import React from "react";
import {
  useNotify,
  FormWithRedirect,
  TextInput,
  BooleanInput,
  SaveButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fetchJSON } from "../dataProvider";
import CepInput from "../common/form/CEPInput";
import { withUser } from "../common/util/hocs";
import { JsonField } from "react-admin-json-view";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 30,
    marginTop: 30,
    marginBottom: 30,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
  },
  mb16: {
    marginBottom: 16,
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "30%",
    height: "100%",
    backgroundColor: "#00a859",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  actions: {
    display: "flex",
  },
  button: {
    marginLeft: "auto",
    marginRight: 10,
  },
  image: {
    maxHeight: "150px",
    padding: 30,
  },
  deleteButton: {
    marginLeft: "auto",
    marginRight: 10,
    color: theme.palette.error.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

function MatrixDistance({ loja }) {
  const classes = useStyles();
  const notify = useNotify();

  const [erro, setErro] = React.useState(null);
  const [distancia, setDistancia] = React.useState(null);

  const handleTestar = (destino) => {
    const params = new URLSearchParams();
    params.set("cnpj", loja.cnpj);
    params.set("debug", destino.debug);
    params.set(
      "origem",
      [
        loja.logradouro,
        loja.numero,
        // loja.bairro,
        loja.cidade,
        loja.uf,
        loja.cep,
      ].join(",")
    );
    params.set(
      "destino",
      [
        destino.logradouro,
        destino.numero,
        // destino.bairro,
        destino.cidade,
        destino.uf,
        destino.cep,
      ].join(",")
    );
    return fetchJSON(`Apps/distanciaV2?${params.toString()}`)
      .then((json) => {
        console.log(json);
        setDistancia(json);
        setErro(null);
        notify("Distância recuperada com sucesso");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao consultar ditância", "warning");
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h5" color="textPrimary" className={classes.mb16}>
          Matrix Distance
        </Typography>
        <FormWithRedirect
          save={handleTestar}
          record={{}}
          toolbar={null}
          submitOnEnter={false}
          render={(formProps) => (
            <>
              <CepInput source="cep" label="CEP" />
              <TextInput source="numero" />

              <TextInput source="logradouro" />
              <TextInput source="bairro" />
              <TextInput source="cidade" />
              <TextInput source="uf" />

              <BooleanInput source="debug" initialValue={true} />
              <SaveButton
                label="Testar"
                disabled={formProps.pristine}
                {...formProps}
              />
            </>
          )}
        />

        {distancia && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">JSON</Typography>
            <JsonField
              record={{ distancia }}
              source="distancia"
              addLabel={true}
              jsonString={false}
              reactJsonOptions={{
                name: null,
                collapsed: false,
                enableClipboard: true,
                displayDataTypes: false,
              }}
            />
          </Box>
        )}
        {erro && (
          <Typography variant="body2" color="error" className={classes.mb16}>
            {erro.message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default withUser(MatrixDistance);
