import React from "react";
import { TextInput, Filter, BooleanInput } from "react-admin";
import { TotemOperacaoInput } from "./TotemOperacaoInput";

const EquipamentoFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome da equipamento" alwaysOn />
    <TextInput source="hardware_like" label="Hardware" />
    <TotemOperacaoInput
      source="totem_operacao"
      label="Modo de operação do POS"
    />
    <BooleanInput source="ativo" label="Ativo" />
  </Filter>
);

export default EquipamentoFilter;
