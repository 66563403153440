import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  SimpleForm,
  required,
  maxValue,
  minValue,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import ImageInputPreview from "../../common/form/ImageInputPreview";

const validateSenha = [required(), maxValue(999999), minValue(999)];

const useStyles = makeStyles({
  formGroup: { display: "inline-block", marginRight: 32, width: "7em" },
});

function AutoatendimentoConfigForm(props) {
  const { record } = props;
  const classes = useStyles();
  return (
    <SimpleForm {...props}>
      <Typography variant="h6" gutterBottom fullWidth>
        Configuração do Autoatendimento
      </Typography>
      <NumberInputSimple
        source="senha"
        label="Senha para sair do app"
        validate={validateSenha}
      />
      <BooleanInput
        source="pergunta_cpf"
        label="Pergunta 'CPF na nota?'"
        fullWidth
      />
      <BooleanInput
        source="pergunta_embalagem"
        label="Pergunta 'Embalar para viagem ou comer aqui?'"
        fullWidth
      />
      <BooleanInput
        source="pergunta_nome"
        label="Exige o nome do cliente?"
        fullWidth
      />

      <Typography variant="h6" gutterBottom fullWidth>
        Pagamentos
      </Typography>
      <BooleanInput
        source="pagamento_debito"
        label="Débito"
        formClassName={classes.formGroup}
      />
      <BooleanInput
        source="pagamento_credito"
        label="Crédito"
        formClassName={classes.formGroup}
      />
      <BooleanInput
        source="pagamento_voucher"
        label="Voucher"
        formClassName={classes.formGroup}
      />
      <BooleanInput
        source="pagamento_pix"
        label="PIX"
        formClassName={classes.formGroup}
      />

      <Typography variant="h6" gutterBottom fullWidth>
        Banners na tela de Descanso
      </Typography>
      <FormDataConsumer>
        {({ formData }) => (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ImageInputPreview
                record={record}
                formData={formData}
                source="fotoUrlBannerDescanso1"
                label="Banner Descanso 1"
              />
            </Grid>
            <Grid item xs={4}>
              <ImageInputPreview
                record={record}
                formData={formData}
                source="fotoUrlBannerDescanso2"
                label="Banner Descanso 2"
              />
            </Grid>
            <Grid item xs={4}>
              <ImageInputPreview
                record={record}
                formData={formData}
                source="fotoUrlBannerDescanso3"
                label="Banner Descanso 3"
              />
            </Grid>
          </Grid>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default AutoatendimentoConfigForm;
