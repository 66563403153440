import React from "react";
import { Title } from "react-admin";
import { Card, CardHeader, CardContent, Stack, Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import isEmpty from "lodash.isempty";
import { useAuthenticated } from "react-admin";
import { formatCurrency, formatDateTimeShort } from "../common/util/formatter";
import { useStyles } from "./ReportDailyInvoicings/styles";
import { fetchJSON } from "../dataProvider";
import Search from "./Search";
import Table from "../common/table/Table";
import { reportPDVEnable } from "../permissionsHelper";

const ReportItensDeletados = () => {
  useAuthenticated();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});

  async function retrieveReportData({ lojaId, startDate, endDate }) {
    setLoading(true);
    try {
      const data = await fetchJSON("relatorio/pdv/itens_deletados", null, {
        lojaId,
        startDate,
        endDate,
      });

      const barChartData = {
        labels: data.totais_por_vendedor.map((i) => i.deletado_por_nome),
        datasets: [
          {
            data: data.totais_por_vendedor.map((i) => i.valor_final),
            label: "Total Deletado",
            yAxisID: "y1",
            type: "bar",
            backgroundColor: "#fa5424",
            borderColor: "#fa5424",
            datalabels: {
              formatter: function (value, context) {
                return new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(value);
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return (
                    context.dataset.label +
                    ": " +
                    new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(context.parsed.y)
                  );
                },
              },
            },
          },
          {
            data: data.totais_por_vendedor.map((i) => i.qtd),
            label: "Quantidade Deletada",
            yAxisID: "y2",
            type: "bar",
            backgroundColor: "#fbc306",
            borderColor: "#fbc306",
          },
        ],
      };

      setData({
        barChartData,
        array: data.array,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const barChartOptions = {
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: "end",
        align: "bottom",
        color: "white",
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y1: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: "linear",
        display: false,
      },
      y2: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: "linear",
        display: false,
      },
    },
  };

  const detailsTableColumns = [
    {
      title: "Deletado por",
      field: "deletado_por_nome",
    },
    {
      title: "Deletado em",
      field: "deletado_em",
      render: (rowData) => formatDateTimeShort(rowData.deletado_em),
    },
    {
      title: "Motivo",
      field: "motivo",
    },
    {
      title: "Comanda",
      field: "comanda",
    },
    {
      title: "Produto",
      field: "descricao",
    },
    {
      title: "Qtd",
      field: "qtd",
    },
    {
      title: "Total",
      field: "valor_final",
      render: (rowData) => formatCurrency(rowData.valor_final),
    },
    {
      title: "Vendido por",
      field: "vendedor_nome",
    },
    {
      title: "Vendido em",
      field: "pedido_em",
      render: (rowData) => formatDateTimeShort(rowData.pedido_em),
    },
  ];

  const dataEmpty = isEmpty(data.array);

  return (
    <Card className={classes.root}>
      <Title title="Itens Deletados" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Stack spacing={5}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                title={"Itens Deletados por Funcionário"}
              />
              <CardContent>
                <Box height={400}>
                  <Bar data={data.barChartData} options={barChartOptions} />
                </Box>
              </CardContent>
            </Card>

            <Table
              title="Detalhamento dos Itens Deletados"
              data={data.array}
              columns={detailsTableColumns}
              isLoading={loading}
              options={{ paging: false }}
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportItensDeletados;

export const itensDeletados = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "itensDeletados",
          to: "/relatorio/itens-deletados",
          primaryText: "Itens deletados",
        }
      : null,
};
