import React, { Component } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./styles.css";
import { DateRangePicker } from "react-dates";
import { withStyles, withStylesPropTypes, css } from "react-with-styles";

const defaultProps = {
  // input related props
  startDateId: "startDate",
  startDatePlaceholderText: "Data inicial",
  endDateId: "endDate",
  endDatePlaceholderText: "Data final",
  disabled: false,
  required: true,
  screenReaderInputMessage: "",
  showClearDates: true,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: "horizontal",
  anchorDirection: "left",
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: () => {},
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat("L"),
  //monthFormat: "dd/MM/yyyy"
};

const presets = [
  {
    text: "hoje",
    start: moment(),
    end: moment(),
  },
  {
    text: "essa semana",
    start: moment().add(-6, "day"),
    end: moment(),
  },
  {
    text: "há um mês",
    start: moment().add(-29, "day"),
    end: moment(),
  },
  {
    text: "há 90 dias",
    start: moment().add(-89, "day"),
    end: moment(),
  },
];

class DateRangePickerWrapper extends Component {
  renderDatePresets = () => {
    const { styles } = this.props;

    return (
      <div {...css(styles.PresetDateRangePicker_panel)}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, this.props.startDate) &&
            isSameDay(end, this.props.endDate);
          return (
            <button
              key={text}
              {...css(
                styles.PresetDateRangePicker_button,
                isSelected && styles.PresetDateRangePicker_button__selected
              )}
              type="button"
              onClick={() =>
                this.props.onDatesChange({ startDate: start, endDate: end })
              }
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <DateRangePicker
        {...this.props}
        renderCalendarInfo={this.renderDatePresets}
      />
    );
  }
}

DateRangePickerWrapper.propTypes = withStylesPropTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

export default withStyles(({ reactDates: { color } }) => ({
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid ${color.core.primary}`,
    color: color.core.primary,
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    color: color.core.white,
    background: color.core.primary,
  },
}))(DateRangePickerWrapper);
