import React from "react";
import {
  TextInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
  minValue,
  FormDataConsumer,
  maxValue,
  required,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import ImageInputPreview from "../../common/form/ImageInputPreview";
import DisponibilidadeItem from "../grupo/DisponibilidadeItem";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const useStyles = makeStyles({
  img: {
    display: "inline-block",
  },
  appOptions: {
    display: "inline-block",
    marginRight: 32,
    width: "11em",
  },
  mr96: {
    display: "inline-block",
    marginRight: 96,
  },
  disponibiidade: {
    display: "inline-block",
    marginRight: 32,
    width: "7em",
  },
  excluir: {
    display: "inline-block",
    marginRight: 32,
    width: "4em",
  },
});

const validateVenda = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];
const validateDetails = [maxLength(10000)];

const ProdutoFormApp = ({ record, formData }) => {
  const classes = useStyles();

  return (
    <>
      <BooleanInput
        source="appDelivery"
        label="Delivery"
        initialValue={true}
        className={classes.appOptions}
        fullWidth
      />

      <BooleanInput
        source="appBalcao"
        label="Balcão"
        initialValue={true}
        className={classes.appOptions}
        fullWidth
      />

      <BooleanInput
        source="appMesa"
        label="Na mesa"
        initialValue={true}
        className={classes.appOptions}
        fullWidth
      />

      <BooleanInput
        source="appDestaque"
        label="Destaque"
        initialValue={false}
        className={classes.appOptions}
        fullWidth
      />

      <BooleanInput
        source="appPdvMobile"
        label="PDV Mobile"
        initialValue={true}
        className={classes.appOptions}
        fullWidth
      />

      <ImageInputPreview record={record} formData={formData} />

      <TextInput source="appNome" label="Nome do produto" fullWidth />

      <NumberInput
        source="appPreco"
        label="Preço de venda"
        initialValue={0}
        validate={validateVenda}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />

      <TextInput
        source="appDetalhes"
        label="Detalhes do produto"
        validate={validateDetails}
        fullWidth
        multiline
        resettable
      />

      <BooleanInput
        source="alcoolico"
        label="Alcoólico"
        formClassName={classes.formGroup}
      />

      <ArrayInput source="disponibilidades" label="Disponibilidade" fullWidth>
        <SimpleFormIterator
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          disableRemove
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => (
              <DisponibilidadeItem
                record={record}
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default ProdutoFormApp;
